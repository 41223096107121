import clsx from 'clsx';
import { ChangeEvent, useState } from 'react';
import { ErrorBlank } from 'src/components';
import { Badge, Blank, Select } from 'src/components/common';
import { GroupContainer } from 'src/container/group';
import { useTeacherTimetableDetail } from 'src/container/teacher-timetable-v3-detail';
import { LectureType, ResponseTimetableV3Dto } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';

interface TimetableDetailPageProps {
  year: number;
  semester: number;
  selectedLectureInfo: (info: ResponseTimetableV3Dto | undefined) => void;
  isTeacherView: (value: boolean) => void;
}

export function TimetableDetailPage({ year, semester, selectedLectureInfo, isTeacherView }: TimetableDetailPageProps) {
  const { allKlassGroupsUnique: allKlassGroups } = GroupContainer.useContext();
  const [teacherTableTime, setTeacherTableTime] = useState(false); // false : 교실보기, true : 과목보기
  const { t } = useLanguage();

  const [selectedMyClass, setSelectedMyClass] = useState(false);
  const [selectedLectureId, setSelectedLectureId] = useState<number | undefined>();

  // 학급 교사 시간표
  const {
    teachers,
    groupId,
    teacherId,
    isLoading,
    error,
    changeTeacher,
    changeKlass,
    timetableV3Klass,
    timetableV3Teacher,
  } = useTeacherTimetableDetail();

  const order = [LectureType.MOVE, LectureType.SELECT, LectureType.FIX, LectureType.UNKNOWN];
  const timetableV3: ResponseTimetableV3Dto[] | undefined = (
    selectedMyClass ? timetableV3Klass : timetableV3Teacher
  )?.sort((a, b) => {
    return order.indexOf(a.type) - order.indexOf(b.type);
  });

  const timesArray: number[] | undefined = timetableV3?.map((item: ResponseTimetableV3Dto) => item.time);
  const maxTime: number = timesArray ? Math.max(...timesArray) : 0;

  const day = new Date().getDay();
  const todayNum = new Date().getDay();

  const timeTableV3Click = (lecture: ResponseTimetableV3Dto | undefined) => {
    if (lecture && lecture?.type !== LectureType.UNKNOWN) {
      setSelectedLectureId(lecture.id);
      isTeacherView(!selectedMyClass);
      selectedLectureInfo(lecture);
    }
  };

  const lectureData = (day: number, time: number) => {
    const lectures = timetableV3?.filter((item) => item.day === day && item.time === time);

    // TODO lectures[0] 을 무조건 가져오는게 아니고, type = move 를 가져와야 함.
    return lectures ? lectures[0] : undefined;
  };

  const handleTeacherSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    changeTeacher(+e.target.value);
    setSelectedLectureId(undefined);
    selectedLectureInfo(undefined);
  };

  const handleKlassSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    changeKlass(+e.target.value);
    setSelectedLectureId(undefined);
    selectedLectureInfo(undefined);
  };

  return (
    <div className="max-w-256 my-2 px-2">
      {isLoading && <Blank reversed />}
      {error && <ErrorBlank />}

      <div className="flex justify-between">
        <div className="max-w-30">
          {/* 학급별 */}
          {selectedMyClass && (
            <Select.lg
              className="max-w-full"
              placeholder={t('selection', '선택')}
              value={groupId}
              onChange={(e) => handleKlassSelectChange(e)}
            >
              <option value="0">{t('selection', '선택')}</option>
              {allKlassGroups?.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name?.replace('학년 ', '-').replace('반', '')}
                </option>
              ))}
            </Select.lg>
          )}

          {/* 교사별 */}
          {!selectedMyClass && (
            <Select.lg
              className="w-full"
              placeholder={t('select_teacher', '선생님 선택')}
              value={teacherId}
              onChange={(e) => handleTeacherSelectChange(e)}
            >
              <option value="0">{t('selection', '선택')}</option>
              {teachers?.map((teacher) => (
                <option key={teacher.id} value={teacher.id}>
                  {teacher?.name}
                </option>
              ))}
            </Select.lg>
          )}
        </div>
        <div className="flex w-full items-center justify-center space-x-2 py-4">
          <Badge
            children={t('by_teacher', '교사별')}
            onClick={() => {
              setSelectedMyClass(false);
            }}
            className={clsx('py-1.5', !selectedMyClass ? 'bg-black text-white' : 'bg-white text-black')}
          />
          <Badge
            children={t('by_class', '학급별')}
            onClick={() => {
              setSelectedMyClass(true);
            }}
            className={clsx('py-1.5', !selectedMyClass ? 'bg-white text-black' : 'bg-black text-white')}
          />
        </div>
      </div>

      <table className="mx-auto w-full table-fixed text-center">
        <thead>
          <tr>
            <th colSpan={4} className=""></th>
            <th colSpan={1} className="" />
            <th
              colSpan={10}
              className={`min-w-max rounded-l-xl py-4 ${day === 1 ? 'bg-grey-1 bg-opacity-10' : 'bg-grey-9'}`}
            >
              {t('monday', '월')}
            </th>
            <th colSpan={10} className={`min-w-max ${day === 2 ? 'bg-brand-1 bg-opacity-20' : 'bg-grey-9'}`}>
              {t('tuesday', '화')}
            </th>
            <th colSpan={10} className={`min-w-max ${day === 3 ? 'bg-brand-1 bg-opacity-20' : 'bg-grey-9'}`}>
              {t('wednesday', '수')}
            </th>
            <th colSpan={10} className={`min-w-max ${day === 4 ? 'bg-brand-1 bg-opacity-20' : 'bg-grey-9'}`}>
              {t('thursday', '목')}
            </th>
            <th
              colSpan={10}
              className={`min-w-max rounded-r-xl ${day === 5 ? 'bg-brand-1 bg-opacity-20' : 'bg-grey-9'}`}
            >
              {t('friday', '금')}
            </th>
          </tr>
          <tr>
            <th className="pb-2"></th>
          </tr>
        </thead>
        <tbody>
          {selectedMyClass && (
            <>
              <tr>
                <td colSpan={4}></td>
                <td colSpan={1}></td>
                {Array.from({ length: 5 }, (_, dayNum) => {
                  const lecture = lectureData(dayNum + 1, 0);
                  return (
                    <td
                      colSpan={10}
                      key={dayNum + 10}
                      className={`min-w-9.5 cursor-pointer 
                      ${dayNum === 0 ? 'rounded-bl-xl rounded-tl-xl ' : ''}  
                      ${dayNum === 4 ? 'rounded-br-xr rounded-tr-xr' : ''}  
                      ${todayNum === dayNum + 1 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-2'}
                      ${
                        lecture?.id !== undefined && selectedLectureId === lecture?.id
                          ? 'bg-yellow-200 text-red-500'
                          : ''
                      }      
                      `}
                      onClick={() => {
                        timeTableV3Click(lecture);
                      }}
                    >
                      {t('morning_inquiry', '조회')}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td className="pb-2"></td>
              </tr>
            </>
          )}

          {Array.from({ length: maxTime }, (_, i) => (
            <tr key={i}>
              <td
                colSpan={4}
                className={`min-w-9.5 min-h-10.5 bg-grey-9 px-1 py-2
                  ${i === 0 ? 'rounded-t-xl' : ''} ${i === maxTime - 1 ? 'rounded-b-xl' : ''}`}
              >
                {i + 1}
              </td>
              <td colSpan={1}></td>
              {Array.from({ length: 5 }, (_, dayNum) => {
                const lecture = lectureData(dayNum + 1, i + 1);
                return (
                  <td
                    colSpan={10}
                    key={dayNum}
                    className={`border-grey-50 min-w-9.5 min-h-10.5 cursor-pointer border px-1 py-2 text-xs md:text-base 
                              ${
                                todayNum === dayNum + 1 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-0'
                              }                              
                              ${i === 0 && dayNum === 0 ? 'rounded-tl-xl border-l-0 border-t-0' : ''}  
                              ${i === 0 && dayNum === 4 ? 'rounded-tr-xl border-r-0 border-t-0' : ''}  
                              ${i === maxTime - 1 && dayNum === 0 ? 'rounded-bl-xl border-b-0 border-l-0' : ''}  
                              ${i === maxTime - 1 && dayNum === 4 ? 'rounded-br-xl border-b-0 border-r-0' : ''}  
                              ${i === 0 || i === maxTime - 1 ? 'border-b-0 border-t-0' : ''}  
                              ${dayNum === 0 || dayNum === 4 ? 'border-l-0 border-r-0' : ''}  
                              ${
                                lecture?.id !== undefined && selectedLectureId === lecture?.id
                                  ? 'bg-yellow-200 text-red-500'
                                  : ''
                              }                                                     
                          `}
                    onClick={() => {
                      timeTableV3Click(lecture);
                    }}
                  >
                    {teacherTableTime || selectedMyClass
                      ? lecture?.type === LectureType.SELECT
                        ? '분반'
                        : lecture?.subject
                      : lecture?.room}
                  </td>
                );
              })}
            </tr>
          ))}

          {!selectedMyClass && (
            <tr>
              <td colSpan={2}></td>
              <td colSpan={53} className="h-30">
                {teacherId === 0
                  ? '선생님을 선택해주세요.'
                  : timetableV3?.length === 0
                  ? '등록된 시간표가 없습니다.'
                  : ''}
              </td>
            </tr>
          )}
          {selectedMyClass && (
            <tr>
              <td colSpan={2}></td>
              <td colSpan={53} className="h-30">
                {groupId === 0 ? '학급을 선택해주세요.' : timetableV3?.length === 0 ? '등록된 시간표가 없습니다.' : ''}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {!selectedMyClass && (
        <div className="flex w-full items-center justify-center space-x-2 py-4">
          <Badge
            children={t('view_classroom', '교실보기')}
            onClick={() => setTeacherTableTime(false)}
            className={clsx('py-1.5', teacherTableTime ? 'bg-white text-black' : 'bg-brand-1 text-white')}
          />
          <Badge
            children={t('view_subject_name', '과목명보기')}
            onClick={() => setTeacherTableTime(true)}
            className={clsx('py-1.5', teacherTableTime ? 'bg-brand-1 text-white' : 'bg-white text-black')}
          />
        </div>
      )}
    </div>
  );
}
