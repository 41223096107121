import { useHistory } from 'react-router-dom';
import { BackButton, Section, TopNavbar } from 'src/components/common';
import { useNotificationLogFindByYearMonth } from 'src/generated/endpoint';
import { NotificationLog } from 'src/generated/model';
import { makeDateToString } from 'src/util/time';

export const NotificationPage = () => {
  const { push } = useHistory();
  const { data } = useNotificationLogFindByYearMonth();
  return (
    <>
      <TopNavbar
        title="알림"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        {data?.map((log: NotificationLog) => (
          <div
            key={log.id}
            onClick={() => push(log.url)}
            className="flex cursor-pointer items-center justify-between border-b border-gray-300 pb-2"
          >
            <div>
              <div className=""> {log.title}</div>
              <div className="text-sm text-gray-500"> {log.body}</div>
            </div>
            <div className="text-sm text-gray-500"> {makeDateToString(log.createdAt)}</div>
          </div>
        ))}
      </Section>
    </>
  );
};
